import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { pdf } from "@react-pdf/renderer";
import { PdfDocument } from "../pdf-document/pdfDocument";
import SearchableDropdown from "../../../components/utilities/searchableDropdown";
import { formatDateTimeToDDMMYY, formatDateTimeToYYYYMMDD } from "../../../utils/formatDateTime";
import { useEffect, useState } from "react";
import { ConfirmSaveModal } from "../modals/confirmSaveModal";
import { useNavigate } from "react-router-dom";

export const NewInvoiceTab = ({
    selectedUser,
    setSelectedUser,
    invoicePositions,
    setInvoicePositions,
    data,
    lastInvoiceNumber,
    setTriggerReload,
    taxRate,
    setTaxRate,
}) => {
    const navigate = useNavigate();

    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfDataState, setPdfDataState] = useState({ positions: [] });
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState(Number(lastInvoiceNumber) + 1);
    const [invoiceDate, setInvoiceDate] = useState(formatDateTimeToYYYYMMDD(new Date()));

    useEffect(() => {
        setPdfDataState({
            invoiceNumber: invoiceNumber,
            invoiceDate: invoiceDate,
            firstName: selectedUser?.firstName,
            lastName: selectedUser?.lastName,
            street: selectedUser?.street,
            number: selectedUser?.streetNumber,
            postalCode: selectedUser?.postalCode,
            city: selectedUser?.city,
            positions: invoicePositions,
            taxRate: taxRate,
        });
    }, [selectedUser, invoicePositions, taxRate, invoiceDate, invoiceNumber]);

    const handleOpenPdf = async () => {
        if (pdfUrl) {
            URL.revokeObjectURL(pdfUrl);
        }

        const blob = await pdf(<PdfDocument pdfData={pdfDataState} />).toBlob();
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        setPdfUrl(url);
    };

    const handleChange = (property, value) => {
        setSelectedUser((prev) => ({
            ...prev,
            [property]: value,
        }));
    };

    return (
        <Container fluid className="p-3">
            {
                <>
                    {selectedUser && (
                        <Row>
                            <Col xs={6} className="mb-3">
                                <Button className="me-3" onClick={() => setSelectedUser(null)}>
                                    Zurück
                                </Button>
                                <Button onClick={() => navigate(`/user/${selectedUser?.id}`)}>Zum Kundenprofil</Button>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>Kundendaten erfassen</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={7}>
                                            <Form>
                                                <Form.Group>
                                                    <SearchableDropdown
                                                        optionsArray={data?.users}
                                                        selectedUser={selectedUser}
                                                        setSelectedUser={setSelectedUser}
                                                        setInvoicePositions={setInvoicePositions}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mt-3">
                                                    <Row>
                                                        <Col xs={9}>
                                                            <Form.Control
                                                                type="text"
                                                                value={selectedUser?.street || ""}
                                                                placeholder="Straße"
                                                                onChange={(e) => handleChange("street", e.target.value)}
                                                            ></Form.Control>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Form.Control
                                                                type="text"
                                                                value={selectedUser?.streetNumber || ""}
                                                                placeholder="Nr."
                                                                onChange={(e) =>
                                                                    handleChange("streetNumber", e.target.value)
                                                                }
                                                            ></Form.Control>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className="mt-3">
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Form.Control
                                                                type="text"
                                                                value={selectedUser?.postalCode || ""}
                                                                placeholder="PLZ"
                                                                onChange={(e) =>
                                                                    handleChange("postalCode", e.target.value)
                                                                }
                                                            ></Form.Control>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control
                                                                type="text"
                                                                value={selectedUser?.city || ""}
                                                                placeholder="Stadt"
                                                                onChange={(e) => handleChange("city", e.target.value)}
                                                            ></Form.Control>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Form>
                                        </Col>

                                        <Col xs={5}>
                                            <Form>
                                                <Form.Group>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <Form.Label>Rechnungsnummer:</Form.Label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Control
                                                                value={invoiceNumber}
                                                                onChange={(e) => setInvoiceNumber(e.target.value)}
                                                                type="text"
                                                            ></Form.Control>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className="mt-3">
                                                    <Row>
                                                        <Col xs={6}>
                                                            <Form.Label>Rechnungsdatum</Form.Label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Control
                                                                type="date"
                                                                value={invoiceDate}
                                                                onChange={(e) => setInvoiceDate(e.target.value)}
                                                            ></Form.Control>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className="mt-3">
                                                    <Row>
                                                        <Col xs={6}>
                                                            <Form.Label>Steuersatz</Form.Label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Select
                                                                value={taxRate}
                                                                onChange={(e) => setTaxRate(Number(e.target.value))}
                                                            >
                                                                <option value={0}>0 %</option>
                                                                <option value={7}>7 %</option>
                                                                <option value={19}>19 %</option>
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <Card className="mt-3">
                                <Card.Header>Rechnungspositionen erfassen</Card.Header>
                                <Card.Body>
                                    {
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                    <th>Nr.</th>
                                                    <th>Beschreibung</th>
                                                    <th>Teilnehmer</th>
                                                    <th>Datum</th>
                                                    <th>Betrag</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {invoicePositions.map((position, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{position.reason}</td>
                                                            <td>{position.participant}</td>
                                                            <td>{formatDateTimeToDDMMYY(position.date)}</td>
                                                            <td>
                                                                {parseFloat(position.amount.replace(",", "."))
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(".", ",")}{" "}
                                                                €
                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                                <tr key={"table-summary-netto"}>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Gesamt Netto:</td>
                                                    <td>
                                                        {(
                                                            (invoicePositions.reduce(
                                                                (total, position) =>
                                                                    total +
                                                                    parseFloat(position.amount.replace(",", ".")),
                                                                0
                                                            ) /
                                                                (100 + taxRate)) *
                                                            100
                                                        )
                                                            .toFixed(2)
                                                            .replace(".", ",")}{" "}
                                                        €
                                                    </td>
                                                </tr>
                                                <tr key={"table-summary-tax"}>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Enthaltene MwSt.</td>
                                                    <td>
                                                        {(() => {
                                                            const totalCost = invoicePositions.reduce(
                                                                (total, position) => {
                                                                    const costValue =
                                                                        parseFloat(
                                                                            position.amount
                                                                                .replace(" €", "")
                                                                                .replace(",", ".")
                                                                        ) || 0;
                                                                    return total + costValue;
                                                                },
                                                                0
                                                            );

                                                            const taxAmount = (
                                                                totalCost *
                                                                (taxRate / (100 + taxRate))
                                                            ).toFixed(2);
                                                            return `${taxAmount.replace(".", ",")} €`;
                                                        })()}
                                                    </td>
                                                </tr>
                                                <tr key={"table-summary-brutto"}>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Gesamt Brutto:</td>
                                                    <td>
                                                        {invoicePositions
                                                            .reduce(
                                                                (total, position) => total + parseFloat(position.amount.replace(" €", "").replace(",", ".")),
                                                                0
                                                            )
                                                            .toFixed(2).replace('.', ',')}{" "}
                                                        €
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}></Col>

                        <Col xs={6} className="d-flex justify-content-end gap-3">
                            <Button variant="warning" onClick={handleOpenPdf}>
                                Rechnungsvorschau
                            </Button>
                            <Button variant="success" onClick={() => setShowSaveModal(true)}>
                                Rechnung abschließen
                            </Button>
                        </Col>
                    </Row>
                </>
            }
            <ConfirmSaveModal
                showSaveModal={showSaveModal}
                setShowSaveModal={setShowSaveModal}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                invoicePositions={invoicePositions}
                invoiceNumber={invoiceNumber}
                invoiceDate={invoiceDate}
                taxRate={taxRate}
                setTriggerReload={setTriggerReload}
                pdfDataState={pdfDataState}
            />
        </Container>
    );
};
