import {
    Alert,
    Card,
    Col,
    Form,
    Row,
} from "react-bootstrap";
import { Loader } from "../../../components/loader";
import {
    Check2Square,
    Square,
} from "react-bootstrap-icons";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { formatDateTimeToDDMMYY } from "../../../utils/formatDateTime";
import { useState } from "react";

export const InvoiceSidebar = ({
    selectedUser,
    setSelectedUser,
    invoicePositions,
    setInvoicePositions,
}) => {
    const { token } = useRecoilValue(userState);
    const [selectedMonth, setSelectedMonth] = useState('0');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const userPaymentQuery = useQuery({
        queryKey: ["user-payment-data", selectedUser?.id],
        queryFn: () =>
            getRequest(`/auth/payment/noinvoice/${selectedUser.id}`, token),
        enabled: !!selectedUser?.id
    });
    const { data } = userPaymentQuery;

    const userPaymentQueryNoInvoice = useQuery({
        queryKey: ["user-payment-data", selectedMonth, selectedYear],
        queryFn: () =>
            getRequest(`/auth/payment/users/noinvoice?month=${selectedMonth}&year=${selectedYear}`, token),
        enabled: !selectedUser
    });
    const { data: usersWithOpenPayments } = userPaymentQueryNoInvoice;

    const handleCardClick = (payment) => {
        setInvoicePositions((prevPositions) => {
            
        const exists = prevPositions.some(
                (pos) => pos === payment
            );

            if (exists) {
                return prevPositions.filter(
                    (pos) =>
                        !(pos === payment)
                );
            } 
            else {
                return [...prevPositions, payment];
            }
        });
    };

    return (
        <Loader
            isActive={userPaymentQuery.isLoading || userPaymentQuery.isFetching || userPaymentQueryNoInvoice.isFetching || userPaymentQueryNoInvoice.isLoading}
        >
            {selectedUser && data && (
                <>
                    <Row>
                        <Col className="text-center border-bottom p-2">
                            Zahlungen ohne Rechnung
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            {data.length === 0 ? (
                                <p className="mt-3 text-center">
                                    Keine offenen Kurskosten ✅
                                </p>
                            ) : (
                                data.map((payment) => {
                                    const isInInvoice = invoicePositions.some((position) => {
                                        return position === payment;
                                    });
                                    return (
                                        <Card 
                                            key={payment.id}
                                            className={`${payment.status == "paid" ? "border-success" : "border-danger"} mt-3`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleCardClick(payment)}
                                        >
                                            <Card.Header>
                                                <Row>
                                                    <Col xs={10}>
                                                        { payment.enrollment ? `${payment.enrollment.course.title}` : `${payment.reason}` }
                                                    </Col>
                                                    <Col xs={2} className="text-end">
                                                        {isInInvoice 
                                                            ?  <Check2Square />
                                                            :   <Square />
                                                        }
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12}>
                                                        { payment.enrollment 
                                                        ?
                                                            `${payment.enrollment.child.firstName} ${payment.enrollment.child.lastName}`
                                                        :
                                                            ""
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={6} className="border-end">
                                                        {formatDateTimeToDDMMYY(payment.date)}
                                                    </Col>
                                                    <Col xs={6} className="text-end">
                                                        {parseFloat(payment.amount.replace(',', '.')).toFixed(2).toString().replace('.', ',')} €
                                                    </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    )
                                })
                            )}
                        </Col>
                    </Row>
                </>
            )}

            {!selectedUser && usersWithOpenPayments && (
                <>
                    <Row className="mt-3">
                        <Col xs={6}>
                            <Form.Select
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                                <option value={0}>Alle Monate</option>
                                <option value={1}>Januar</option>
                                <option value={2}>Februar</option>
                                <option value={3}>März</option>
                                <option value={4}>April</option>
                                <option value={5}>Mai</option>
                                <option value={6}>Juni</option>
                                <option value={7}>Juli</option>
                                <option value={8}>August</option>
                                <option value={9}>September</option>
                                <option value={10}>Oktober</option>
                                <option value={11}>November</option>
                                <option value={12}>Dezember</option>
                            </Form.Select>
                        </Col>
                        <Col xs={6}>
                            <Form.Select
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                            >
                                <option value={0}>Alle Jahre</option>
                                <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                            </Form.Select>
                        </Col>
                        
                    </Row>
                    <Row>
                        {usersWithOpenPayments.length > 0 &&
                            <Col xs={12} className="mb-3">
                                {usersWithOpenPayments.map((user) => {
                                    return (
                                        <Card 
                                            className="mt-3"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => setSelectedUser(user)}
                                        >
                                            <Card.Header>{user.firstName} {user.lastName}</Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={10}>
                                                        Ungebuchte Zahlungen:
                                                    </Col>
                                                    <Col xs={2} className="text-end">
                                                        {user.noInvoiceTotal}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    );
                                })}
                            </Col>
                        }
                        {usersWithOpenPayments.length == 0 &&
                            <p className="mt-3 text-center">Keine ungebuchten Zahlungen im ausgewählten Zeitraum 🥳</p>
                        }
                    </Row>
                </>
            )}
        </Loader>
    );
};
