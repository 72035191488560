import { useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Row } from "react-bootstrap"
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../../../utils/apiRequest";
import { Loader } from "../../../../components/loader";
import { useEffect, useState } from "react";
import { BankDetailModal } from "../../../../components/modals/bankDetailModal";
import { UpdatePaymentModal } from "../../../../components/modals/updatePaymentModal";
import { NewPaymentModal } from "../../../../components/modals/newPaymentModal";
import { formatDateTimeToDDMMYY } from "../../../../utils/formatDateTime";
import { shortenString } from "../../../../utils/shortenSting";


export const UserUnpaidEnrollment = ({user, token}) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [totalCost, setTotalCost] = useState(0);
    const [showBankModal, setShowBankModal] = useState(false);
    const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
    const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
    const [paymentId, setPaymentId] = useState("");
    const [showToast, setShowToast] = useOutletContext();

    const unpaidPayment = useQuery({
        queryKey: ["unpaid-payments-by-id"],
        queryFn: () => getRequest(`/auth/payment/unpaid/${id ? id : user.id}`, token), //TODO: Why am I checking for id? // Ok if we have access to ID we are admin getting the ID from the URL, if not we are user getting id from our user data.
        enabled: !showUpdatePaymentModal
    });
    const { data } = unpaidPayment;

    useEffect(() => {
        if (data) {
            const totalCostCalculated = data.reduce((acc, payment) => {
                const cleanAmount = payment.amount.replace(',', '.');
                const numericAmount = parseFloat(cleanAmount);
                return acc + numericAmount;
            }, 0);
    
            setTotalCost(totalCostCalculated.toFixed(2).toString().replace('.', ',')); // Format at the end
        }
    }, [data]);

    const handlePaypal = (total) => {
        window.open(`https://paypal.me/kiddiefyallaboutkids/${total}`)
    }

    const handlePaymentReminderButton = async (id) => {
        const response = await postRequest('/auth/payment-reminder', token, {id: id});
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            })
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Zahlungserinnerung erfolgreich verschickt. 😊',
                toastAppearance: 'success'
            })
        }
    }

    return (
        <Loader isActive={unpaidPayment.isLoading || unpaidPayment.isFetching}>
            {data &&
                <Card className="mt-3">
                    <Card.Header>Offene Zahlungen</Card.Header>
                    <Card.Body className="pt-0 pb-0 no-border-on-last-child ">
                        {data.length < 1 
                            ?
                                <p className="m-0 pt-3 pb-3">Keine offenen Kursrechnungen.</p>
                            :
                                <>
                                    {data.map((payment) => {
                                        return (
                                            <Row
                                                key={payment.id}
                                                className={`${payment.enrollment ? 'hoverable-title' : 'hoverable-title-alternative'} text-danger pt-3 pb-3 border-bottom`}
                                                onClick={
                                                    () => {
                                                        if (!payment.enrollment && id) {
                                                            setPaymentId(payment.id);
                                                            setShowUpdatePaymentModal(true);
                                                        }
                                                        else if (payment.enrollment && id) {
                                                            navigate(`/course/${payment.enrollment.course.id}`)
                                                        }
                                                    }
                                                }
                                            >
                                                <Col  xs={3}>
                                                    {payment.participant}
                                                </Col>

                                                <Col  xs={3} className="text-center">
                                                    {shortenString(payment.reason, 10)}
                                                </Col>

                                                <Col xs={3} className="text-end">
                                                    {formatDateTimeToDDMMYY(payment.date)}
                                                </Col>

                                                <Col xs={3} className="text-end">
                                                    {parseFloat(payment.amount.replace(',', '.')).toFixed(2).toString().replace('.', ',')} €
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                    <Row className="pt-3 pb-3">
                                        <Col xs={9}>Offene Gesamtforderungen:</Col>
                                        <Col xs={3} className="text-end">{totalCost} €</Col>
                                    </Row>
                                </>
                        }
                    </Card.Body>
                    <Card.Footer className="p-3">
                        {!id &&
                            <Row>
                                <Col xs={6}>
                                    <Button onClick={() => handlePaypal(totalCost)} className="w-100">Paypal</Button>
                                </Col>
                                <Col xs={6}>
                                    <Button 
                                        className="w-100"
                                        onClick={() => setShowBankModal(true)}
                                    >
                                        Überweisung
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {id &&
                            <Row>
                                <Col xs={12}>
                                    <Button
                                        className="me-3"
                                        onClick={() => setShowNewPaymentModal(true)}
                                    >
                                        Neue Zahlung
                                    </Button>
                                    <Button
                                        onClick={() => handlePaymentReminderButton(user.id)}
                                        variant="warning"
                                    >
                                        Zahlungserinnerung senden
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Card.Footer>
                    <BankDetailModal 
                        show={showBankModal}
                        hide={() => setShowBankModal(false)}
                        user={user}
                    />
                    {id &&
                        <>
                            <UpdatePaymentModal 
                                showUpdatePaymentModal={showUpdatePaymentModal}
                                setShowUpdatePaymentModal={setShowUpdatePaymentModal}
                                paymentId={paymentId}
                            />
                            <NewPaymentModal 
                                showNewPaymentModal={showNewPaymentModal}
                                setShowNewPaymentModal={setShowNewPaymentModal}
                                userId={id}
                                refetch={unpaidPayment.refetch}
                            />
                        </> 
                    }
                </Card>         
            }
        </Loader>
    )
}